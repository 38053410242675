import React from 'react';
import { motion } from 'framer-motion';
import PageTitle from '../components/common/PageTitle';

const TermsOfServicePage = () => {
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.1 
      }
    }
  };
  
  const itemVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <div className="terms-of-service-page">
      <PageTitle 
        title="Terms of Service" 
        subtitle="Guidelines for using our website and services" 
      />

      <motion.div 
        className="container"
        variants={pageVariants}
        initial="initial"
        animate="animate"
      >
        <motion.div className="terms-content" variants={itemVariants}>
          <div className="terms-section">
            <h2>Introduction</h2>
            <p>
              Last Updated: March 2025
            </p>
            <p>
              These Terms of Service ("Terms") govern your access to and use of the Guntaka Ventures, LLC website 
              and services. By accessing or using our website, you agree to be bound by these Terms. If you do not 
              agree to these Terms, please do not use our website.
            </p>
          </div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Use of Website</h2>
            <p>
              Subject to these Terms, Guntaka Ventures, LLC grants you a non-exclusive, non-transferable, revocable 
              license to access and use our website for personal, non-commercial purposes. You may not:
            </p>
            <ul className="terms-list">
              <li>Use our website in any way that violates applicable laws or regulations</li>
              <li>Use our website to transmit any viruses, malware, or other harmful code</li>
              <li>Attempt to gain unauthorized access to any portion of our website or systems</li>
              <li>Modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of our website</li>
              <li>Use any robot, spider, or other automated device to access our website</li>
              <li>Remove any copyright, trademark, or other proprietary notices from our website</li>
            </ul>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Intellectual Property</h2>
            <p>
              All content on our website, including text, graphics, logos, images, and software, is the property of 
              Guntaka Ventures, LLC or its licensors and is protected by copyright, trademark, and other intellectual 
              property laws. You may not use, reproduce, distribute, or create derivative works from our content without 
              our express written permission.
            </p>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Third-Party Links</h2>
            <p>
              Our website may contain links to third-party websites, products, or services. These links are provided for 
              your convenience only and do not constitute an endorsement or approval by Guntaka Ventures, LLC. We are not 
              responsible for the content, privacy practices, or business practices of any third-party websites.
            </p>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Disclaimer of Warranties</h2>
            <p>
              OUR WEBSITE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, 
              INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
              NON-INFRINGEMENT, OR COURSE OF PERFORMANCE.
            </p>
            <p>
              We do not warrant that our website will function uninterrupted or error-free, that defects will be corrected, 
              or that our website or the servers that make it available are free of viruses or other harmful components.
            </p>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Limitation of Liability</h2>
            <p>
              IN NO EVENT SHALL GUNTAKA VENTURES, LLC, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU 
              FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM 
              ANY:
            </p>
            <ul className="terms-list">
              <li>Errors, mistakes, or inaccuracies of content</li>
              <li>Personal injury or property damage of any nature whatsoever resulting from your access to and use of our website</li>
              <li>Unauthorized access to or use of our secure servers and/or any personal information stored therein</li>
              <li>Interruption or cessation of transmission to or from our website</li>
              <li>Bugs, viruses, trojan horses, or the like that may be transmitted to or through our website</li>
              <li>Any loss or damage of any kind incurred as a result of your use of our website</li>
            </ul>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Indemnification</h2>
            <p>
              You agree to defend, indemnify, and hold harmless Guntaka Ventures, LLC, its officers, directors, employees, 
              and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, 
              and expenses (including but not limited to attorney's fees) arising from:
            </p>
            <ul className="terms-list">
              <li>Your use of and access to our website</li>
              <li>Your violation of any term of these Terms</li>
              <li>Your violation of any third-party right, including without limitation any copyright, property, or privacy right</li>
              <li>Any claim that your content caused damage to a third party</li>
            </ul>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Governing Law</h2>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of the United States and the State of 
              California, without regard to its conflict of law provisions. You agree to submit to the personal and exclusive 
              jurisdiction of the courts located within California for the resolution of any disputes.
            </p>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Modification of Terms</h2>
            <p>
              We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting 
              the updated Terms on our website. Your continued use of our website after any changes to these Terms constitutes 
              your acceptance of such changes.
            </p>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Termination</h2>
            <p>
              We may terminate or suspend your access to our website immediately, without prior notice or liability, for any 
              reason whatsoever, including without limitation if you breach these Terms. Upon termination, your right to use 
              our website will immediately cease.
            </p>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Severability</h2>
            <p>
              If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the 
              remaining provisions shall be enforced to the fullest extent under law.
            </p>
          </motion.div>

          <motion.div className="terms-section" variants={itemVariants}>
            <h2>Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            <p className="contact-info">
              Guntaka Ventures, LLC<br />
              Email: info@guntakaventures.com
            </p>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default TermsOfServicePage; 