import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Navbar from './Navbar';
import Particles from './Particles';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Particles />
      <Navbar />
      <AnimatePresence mode="wait">
        <motion.main 
          className="main-content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </motion.main>
      </AnimatePresence>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">
            <span>Guntaka Ventures, LLC</span>
            <p>Building innovative brands for the future</p>
          </div>
          <div className="footer-links">
            <div className="footer-column">
              <h4>Company</h4>
              <ul>
                <li><a href="/about">About Us</a></li>
                <li><a href="/contact">Careers</a></li>
                <li><a href="/contact">Contact</a></li>
              </ul>
            </div>
            <div className="footer-column">
              <h4>Legal</h4>
              <ul>
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/terms">Terms of Service</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>&copy; 2025 Guntaka Ventures, LLC. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout; 