import React from 'react';
import Hero from '../components/common/Hero';
import { motion } from 'framer-motion';
import BrandCard from '../components/brands/BrandCard';
import brands from '../data/brands';

const HomePage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        when: 'beforeChildren',
        staggerChildren: 0.3
      }
    }
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: 'spring', 
        stiffness: 100,
        duration: 0.5
      }
    }
  };

  // Only show first 3 brands on homepage
  const featuredBrands = brands.slice(0, 3);

  return (
    <div className="home-page">
      <Hero />
      
      <motion.section 
        className="home-featured-brands"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div 
          className="section-header"
          variants={sectionVariants}
          style={{
            textAlign: 'center',
            margin: '60px 0 30px'
          }}
        >
          <motion.h2
            style={{
              fontSize: '2.2rem',
              fontWeight: 'bold',
              margin: '0 0 15px',
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '300% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            animate={{
              backgroundPosition: ['0% center', '300% center'],
              transition: {
                repeat: Infinity,
                duration: 10,
                ease: 'linear'
              }
            }}
          >
            Featured Brands
          </motion.h2>
          <motion.div
            className="section-accent-line"
            initial={{ width: 0 }}
            animate={{ width: '80px' }}
            transition={{ delay: 0.5, duration: 0.7 }}
            style={{
              height: '3px',
              background: 'linear-gradient(90deg, #9C7A3C, #BFA046, #C8B273)',
              margin: '15px auto',
              borderRadius: '2px'
            }}
          />
          <motion.p
            style={{
              fontSize: '1.1rem',
              color: '#C8B273',
              maxWidth: '700px',
              margin: '0 auto'
            }}
          >
            Explore our portfolio of innovative and forward-thinking brands
          </motion.p>
        </motion.div>
        
        <motion.div 
          className="brands-grid"
          variants={containerVariants}
        >
          {featuredBrands.map((brand, index) => (
            <BrandCard key={brand.id} brand={brand} index={index} />
          ))}
        </motion.div>
      </motion.section>
    </div>
  );
};

export default HomePage; 