import React from 'react';
import { motion } from 'framer-motion';
import PageTitle from '../components/common/PageTitle';

const PrivacyPolicyPage = () => {
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.1 
      }
    }
  };
  
  const itemVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <div className="privacy-policy-page">
      <PageTitle 
        title="Privacy Policy" 
        subtitle="How we collect, use, and protect your information" 
      />

      <motion.div 
        className="container"
        variants={pageVariants}
        initial="initial"
        animate="animate"
      >
        <motion.div className="privacy-content" variants={itemVariants}>
          <div className="policy-section">
            <h2>Introduction</h2>
            <p>
              Last Updated: March 2025
            </p>
            <p>
              At Guntaka Ventures, LLC ("we," "us," or "our"), we respect your privacy and are committed to protecting 
              your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information 
              when you visit our website or interact with our brands.
            </p>
            <p>
              Please read this Privacy Policy carefully. By accessing or using our website, you acknowledge that you have 
              read, understood, and agree to be bound by this Privacy Policy.
            </p>
          </div>

          <motion.div className="policy-section" variants={itemVariants}>
            <h2>Information We Collect</h2>
            <p>
              We may collect the following types of information:
            </p>
            <h3>Personal Information</h3>
            <p>
              When you contact us or subscribe to our newsletter, we may collect personal information such as:
            </p>
            <ul className="policy-list">
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Mailing address</li>
            </ul>
            
            <h3>Usage Information</h3>
            <p>
              We automatically collect certain information about your device and how you interact with our website, including:
            </p>
            <ul className="policy-list">
              <li>IP address</li>
              <li>Browser type</li>
              <li>Operating system</li>
              <li>Pages visited</li>
              <li>Time and date of visits</li>
            </ul>
            
            <h3>Cookies and Similar Technologies</h3>
            <p>
              We use cookies and similar tracking technologies to collect information about your browsing activities. 
              You can set your browser to refuse cookies, but this may limit your ability to use certain features of our website.
            </p>
          </motion.div>

          <motion.div className="policy-section" variants={itemVariants}>
            <h2>How We Use Your Information</h2>
            <p>
              We may use the information we collect for various purposes, including:
            </p>
            <ul className="policy-list">
              <li>Providing, maintaining, and improving our website and services</li>
              <li>Responding to your inquiries, comments, or questions</li>
              <li>Sending you marketing communications about our products and services</li>
              <li>Analyzing usage patterns to enhance user experience</li>
              <li>Protecting our rights, property, or safety, and that of our users</li>
              <li>Complying with legal obligations</li>
            </ul>
          </motion.div>

          <motion.div className="policy-section" variants={itemVariants}>
            <h2>Information Sharing</h2>
            <p>
              We may share your information with:
            </p>
            <ul className="policy-list">
              <li>Service providers who perform services on our behalf</li>
              <li>Business partners with whom we jointly offer products or services</li>
              <li>Law enforcement or other governmental authorities in response to a legal request</li>
              <li>Other parties in connection with a merger, acquisition, or sale of all or a portion of our assets</li>
            </ul>
            <p>
              We do not sell your personal information to third parties.
            </p>
          </motion.div>

          <motion.div className="policy-section" variants={itemVariants}>
            <h2>Data Security</h2>
            <p>
              We implement appropriate security measures to protect your personal information from unauthorized access, 
              alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic 
              storage is 100% secure, and we cannot guarantee absolute security.
            </p>
          </motion.div>

          <motion.div className="policy-section" variants={itemVariants}>
            <h2>Your Rights</h2>
            <p>
              Depending on your location, you may have certain rights regarding your personal information, such as:
            </p>
            <ul className="policy-list">
              <li>Access to your personal information</li>
              <li>Correction of inaccurate or incomplete information</li>
              <li>Deletion of your personal information</li>
              <li>Restriction or objection to certain processing activities</li>
              <li>Data portability</li>
              <li>Withdrawal of consent</li>
            </ul>
            <p>
              To exercise these rights, please contact us using the information provided below.
            </p>
          </motion.div>

          <motion.div className="policy-section" variants={itemVariants}>
            <h2>Children's Privacy</h2>
            <p>
              Our website is not intended for children under 13 years of age. We do not knowingly collect personal 
              information from children under 13. If we learn that we have collected personal information from a child 
              under 13, we will promptly delete that information.
            </p>
          </motion.div>

          <motion.div className="policy-section" variants={itemVariants}>
            <h2>Updates to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time. The updated version will be indicated by an updated 
              "Last Updated" date at the top of this Privacy Policy. We encourage you to review this Privacy Policy 
              frequently to stay informed about how we are protecting your information.
            </p>
          </motion.div>

          <motion.div className="policy-section" variants={itemVariants}>
            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy or our privacy practices, please contact us at:
            </p>
            <p className="contact-info">
              Guntaka Ventures, LLC<br />
              Email: info@guntakaventures.com
            </p>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default PrivacyPolicyPage; 