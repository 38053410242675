import rnLogo from '../assets/images/rn_logo.svg';
import wgLogo from '../assets/images/wg_logo.svg';
import logo from '../assets/images/logo.svg';

const brands = [
  {
    id: 1,
    name: "Warhorse Gear",
    slug: "warhorse-gear",
    logo: wgLogo,
    heroImage: "/images/brands/warhorse-hero.jpg",
    primaryColor: "#4A90E2",
    secondaryColor: "#50E3C2",
    description: "Cutting-edge fitness and sports equipment powered by technology. We blend innovation with performance to create products that enhance your workout experience and help you achieve peak performance.",
    shortDescription: "Tech-driven fitness and sports equipment",
    services: [
      "Smart Fitness Gear",
      "Performance Tracking",
      "Wearable Tech",
      "Sports Equipment"
    ],
    contactEmail: "info@warhorsegear.com",
    contactPhone: "+1 (555) 123-4567",
    socialMedia: {
      twitter: "https://twitter.com/warhorsegear",
      linkedin: "https://linkedin.com/company/warhorsegear",
      instagram: "https://instagram.com/warhorsegear"
    }
  },
  {
    id: 2,
    name: "Rahim Naturals",
    slug: "rahim-naturals",
    logo: rnLogo,
    heroImage: "/images/brands/rahim-naturals-hero.jpg",
    primaryColor: "#4CAF50",
    secondaryColor: "#8BC34A",
    description: "Premium skincare and beauty products made with natural, ethically-sourced ingredients. Our formulations combine ancient beauty traditions with modern science to nourish your skin and enhance your natural beauty.",
    shortDescription: "Natural skincare and beauty products",
    services: [
      "Organic Skincare",
      "Natural Beauty",
      "Holistic Wellness",
      "Ethical Sourcing"
    ],
    contactEmail: "hello@rahimnaturals.com",
    contactPhone: "+1 (555) 987-6543",
    socialMedia: {
      twitter: "https://twitter.com/rahimnaturals",
      linkedin: "https://linkedin.com/company/rahimnaturals",
      instagram: "https://instagram.com/rahimnaturals"
    }
  },
  {
    id: 3,
    name: "Coming Soon",
    slug: "coming-soon",
    logo: logo,
    heroImage: "/images/brands/finpro-hero.jpg",
    primaryColor: "#BFA046",
    secondaryColor: "#9C7A3C",
    description: "We're working on something exciting! A new brand will be joining our portfolio soon. Stay tuned for more information.",
    shortDescription: "New brand coming soon",
    isUpcoming: true,
    launchDate: "Q4 2024",
    services: [
      "To Be Announced",
      "More Details Coming Soon"
    ],
    contactEmail: "info@guntakaventures.com",
    contactPhone: "+1 (555) 000-0000",
    socialMedia: {
      linkedin: "https://linkedin.com/company/guntakaventures",
      twitter: "https://twitter.com/guntakaventures"
    }
  }
];

export default brands; 