import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';

const Hero = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.8, 
        when: 'beforeChildren',
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  const goldShineVariants = {
    initial: {
      backgroundPosition: '200% 0'
    },
    animate: {
      backgroundPosition: '-200% 0',
      transition: {
        repeat: Infinity,
        duration: 10,
        ease: 'linear'
      }
    }
  };

  return (
    <motion.div 
      className="hero-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="hero-content">
        <motion.div 
          className="hero-title-container"
          variants={itemVariants}
        >
          <motion.h1 
            className="hero-title"
            style={{ 
              marginBottom: '0.2rem',
              textAlign: 'center'
            }}
          >
            Welcome to
          </motion.h1>
          <motion.h1 
            className="hero-title"
            style={{ marginTop: '0' }}
          >
            <motion.span 
              style={{
                background: 'linear-gradient(90deg, #BFA046, #E2D1A7, #9C7A3C, #BFA046)',
                backgroundSize: '300% 100%',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                fontSize: '1.2em'
              }}
              variants={goldShineVariants}
              initial="initial"
              animate="animate"
            >
              Guntaka Ventures
            </motion.span>
          </motion.h1>
        </motion.div>
        
        <motion.p 
          className="hero-subtitle"
          variants={itemVariants}
        >
          A collection of innovative brands making a difference
        </motion.p>
        
        <motion.div
          className="hero-buttons"
          variants={itemVariants}
        >
          <Link to="/brands">
            <motion.button 
              className="btn primary-btn"
              whileHover={{ 
                scale: 1.05,
                boxShadow: '0 0 25px rgba(191, 160, 70, 0.6)'
              }}
              whileTap={{ scale: 0.95 }}
            >
              Discover Our Brands
            </motion.button>
          </Link>
          
          <Link to="/contact">
            <motion.button 
              className="btn secondary-btn"
              whileHover={{ 
                scale: 1.05,
                borderColor: '#C8B273',
                boxShadow: '0 0 15px rgba(191, 160, 70, 0.4)'
              }}
              whileTap={{ scale: 0.95 }}
            >
              Contact Us
            </motion.button>
          </Link>
        </motion.div>
      </div>
      
      <motion.div 
        className="hero-image"
        variants={itemVariants}
        animate={{
          y: [0, -25, 0],
          filter: ['drop-shadow(0 0 10px rgba(191, 160, 70, 0.3))', 'drop-shadow(0 0 20px rgba(191, 160, 70, 0.6))', 'drop-shadow(0 0 10px rgba(191, 160, 70, 0.3))'],
          transition: {
            duration: 6,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut'
          }
        }}
      >
        <img src={logo} alt="Guntaka Ventures Logo" />
        
        {/* Gold shimmer overlay */}
        <motion.div
          className="hero-image-overlay"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle at 50% 50%, rgba(191, 160, 70, 0.2) 0%, rgba(191, 160, 70, 0) 50%)',
            pointerEvents: 'none'
          }}
          animate={{
            opacity: [0.5, 0.8, 0.5],
            scale: [1, 1.05, 1],
            transition: {
              duration: 8,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: 'easeInOut'
            }
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default Hero; 