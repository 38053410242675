import React from 'react';
import { motion } from 'framer-motion';
import PageTitle from '../components/common/PageTitle';

const AboutPage = () => {
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.1 
      }
    }
  };
  
  const itemVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <div className="about-page">
      <PageTitle 
        title="About Us" 
        subtitle="Learn more about Guntaka Ventures and our mission" 
      />

      <motion.div 
        className="container"
        variants={pageVariants}
        initial="initial"
        animate="animate"
      >
        <motion.div className="about-content" variants={itemVariants}>
          <div className="about-section">
            <h2>Our Story</h2>
            <p>
              Founded in 2024, Guntaka Ventures, LLC is an umbrella company established to develop and bring to market innovative 
              fitness and healthcare products that improve people's lives. We combine technology with proven health 
              science to create solutions that are both effective and accessible.
            </p>
            
            <p>
              Our mission is to empower individuals to take control of their health and wellness through thoughtfully designed 
              products that address real needs in the fitness and healthcare spaces.
            </p>
          </div>

          <motion.div className="about-section" variants={itemVariants}>
            <h2>What We Do</h2>
            <p>
              At Guntaka Ventures, we develop, acquire, and nurture brands in the fitness and healthcare sectors. 
              Our portfolio includes, but is not limited to:
            </p>
            
            <ul className="about-list">
              <li>Fitness equipment and technology</li>
              <li>Wellness supplements and nutrition products</li>
              <li>Rehabilitation and recovery tools</li>
              <li>Skincare products</li>
            </ul>
            
            <p>
              Each of our brands receives the strategic guidance, operational support, and resources needed 
              to maximize their potential and deliver exceptional value to customers.
            </p>
          </motion.div>

          <motion.div className="about-section" variants={itemVariants}>
            <h2>Our Approach</h2>
            <p>
              We believe in a holistic approach to health and wellness that considers the interconnected nature of physical fitness, 
              nutrition, recovery, and mental wellbeing. This philosophy guides our product development and brand strategies.
            </p>
            
            <p>
              Our team combines expertise in healthcare, fitness science, product design, and business development to create 
              products that are not only effective but also user-friendly and accessible to a wide range of consumers.
            </p>
            
            <p>
              As we grow, we remain committed to our founding principles of innovation, quality, and consumer trust. We see each 
              product as an opportunity to make a positive impact on someone's health journey.
            </p>
          </motion.div>

          <motion.div className="about-section" variants={itemVariants}>
            <h2>Looking Forward</h2>
            <p>
              As a young company founded in 2024, we're just beginning our journey. We're excited about the future of health and 
              fitness technology and the role we can play in shaping it. Our roadmap includes expanding our product offerings, 
              entering new markets, and continuing to innovate at the intersection of health, wellness, and technology.
            </p>
            
            <p>
              We invite you to join us on this journey and discover how our brands can enhance your path to better health and fitness.
            </p>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default AboutPage; 