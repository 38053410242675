import React from 'react';
import { motion } from 'framer-motion';

const PageTitle = ({ title, subtitle }) => {
  return (
    <motion.div 
      className="page-title-container"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      style={{
        padding: '40px 20px',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <motion.h1
        className="page-title"
        style={{
          margin: '0 0 10px 0',
          fontSize: '2.5rem',
          fontWeight: 'bold',
          background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
          backgroundSize: '300% 100%',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text'
        }}
        animate={{
          backgroundPosition: ['0% center', '300% center'],
          transition: {
            repeat: Infinity,
            duration: 10,
            ease: 'linear'
          }
        }}
      >
        {title}
      </motion.h1>
      
      {subtitle && (
        <motion.p
          className="page-subtitle"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          style={{
            margin: '0',
            fontSize: '1.1rem',
            color: '#C8B273'
          }}
        >
          {subtitle}
        </motion.p>
      )}
      
      {/* Gold accent line */}
      <motion.div
        className="title-accent"
        initial={{ width: 0 }}
        animate={{ width: '80px' }}
        transition={{ delay: 0.5, duration: 0.7 }}
        style={{
          height: '3px',
          background: 'linear-gradient(90deg, #9C7A3C, #BFA046, #C8B273)',
          margin: '15px auto 0',
          borderRadius: '2px'
        }}
      />
      
      {/* Background accents */}
      <motion.div 
        className="title-bg-accent left"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 0.1, scale: 1 }}
        transition={{ delay: 0.3, duration: 1 }}
        style={{
          position: 'absolute',
          top: '-50px',
          left: '-50px',
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          background: 'radial-gradient(circle, #BFA046 0%, transparent 70%)',
          zIndex: -1
        }}
      />
      
      <motion.div 
        className="title-bg-accent right"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 0.1, scale: 1 }}
        transition={{ delay: 0.6, duration: 1 }}
        style={{
          position: 'absolute',
          bottom: '-50px',
          right: '-50px',
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          background: 'radial-gradient(circle, #BFA046 0%, transparent 70%)',
          zIndex: -1
        }}
      />
    </motion.div>
  );
};

export default PageTitle; 