import React from 'react';
import { motion } from 'framer-motion';
import BrandCard from '../components/brands/BrandCard';
import brands from '../data/brands';
import PageTitle from '../components/common/PageTitle';

const BrandsPage = () => {
  // Container animation
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  return (
    <div className="brands-page">
      <PageTitle 
        title="Our Brands" 
        subtitle="Explore our diverse portfolio of innovative brands" 
      />
      
      <motion.div 
        className="brands-grid"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {brands.map((brand, index) => (
          <BrandCard key={brand.id} brand={brand} index={index} />
        ))}
      </motion.div>
    </div>
  );
};

export default BrandsPage; 