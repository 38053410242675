import React from 'react';
import { motion } from 'framer-motion';
import PageTitle from '../components/common/PageTitle';
import brands from '../data/brands';
import '../styles/RahimNaturalsPage.css';

const RahimNaturalsPage = () => {
  // Animation variants
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const childVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  const shineAnimation = {
    initial: {
      backgroundPosition: '200% center',
    },
    animate: {
      backgroundPosition: '-200% center',
      transition: {
        repeat: Infinity,
        duration: 10,
        ease: 'linear',
      },
    },
  };

  // Get the Rahim Naturals brand data
  const rahimNaturals = brands.find(b => b.slug === 'rahim-naturals');

  return (
    <motion.div 
      className="brand-page-container"
      variants={pageVariants}
      initial="initial"
      animate="animate"
    >
      <PageTitle 
        title="Rahim Naturals" 
        subtitle="Natural skincare and beauty products" 
      />
      
      <div className="brand-page-content">
        <motion.section className="brand-hero-section" variants={childVariants}>
          <motion.div 
            className="brand-image-container"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.7 }}
          >
            <img 
              src={rahimNaturals.logo} 
              alt="Rahim Naturals logo" 
              className="brand-logo"
              style={{
                maxWidth: '250px',
                filter: 'drop-shadow(0 0 15px rgba(191, 160, 70, 0.4))'
              }}
            />
          </motion.div>
          
          <motion.div className="brand-intro" variants={childVariants}>
            <h2>Beauty from Nature</h2>
            <p>{rahimNaturals.description}</p>
          </motion.div>
        </motion.section>

        <motion.section className="brand-services-section" variants={childVariants}>
          <motion.h2
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            variants={shineAnimation}
            initial="initial"
            animate="animate"
          >
            Our Products & Services
          </motion.h2>
          <div className="services-grid">
            {rahimNaturals.services.map((service, index) => (
              <motion.div 
                key={index} 
                className="service-card"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0px 5px 15px rgba(191, 160, 70, 0.3)",
                  borderColor: "rgba(191, 160, 70, 0.6)",
                  y: -5
                }}
                style={{
                  padding: '25px',
                  background: 'rgba(18, 18, 18, 0.7)',
                  borderRadius: '8px',
                  border: '1px solid rgba(191, 160, 70, 0.3)',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                <h3 style={{ color: '#BFA046', marginBottom: '10px' }}>{service}</h3>
                
                {/* Service card shine effect */}
                <motion.div
                  className="service-shine"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: '-100%',
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(191, 160, 70, 0.1) 50%, rgba(255,255,255,0) 100%)',
                    transform: 'skewX(-20deg)',
                    zIndex: 1,
                    pointerEvents: 'none'
                  }}
                  animate={{
                    left: ['100%', '-100%'],
                    transition: {
                      repeat: Infinity,
                      duration: 8,
                      ease: 'easeInOut',
                      delay: index * 0.5
                    }
                  }}
                />
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section className="brand-features-section" variants={childVariants}>
          <motion.h2
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            variants={shineAnimation}
            initial="initial"
            animate="animate"
          >
            Why Choose Rahim Naturals
          </motion.h2>
          
          <div className="features-grid">
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              style={{
                padding: '25px',
                background: 'rgba(18, 18, 18, 0.7)',
                borderRadius: '8px',
                border: '1px solid rgba(191, 160, 70, 0.3)'
              }}
            >
              <h3 style={{ color: '#BFA046' }}>Natural Ingredients</h3>
              <p>We source only the finest natural ingredients, ensuring our products are gentle on your skin and the environment.</p>
            </motion.div>
            
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              style={{
                padding: '25px',
                background: 'rgba(18, 18, 18, 0.7)',
                borderRadius: '8px',
                border: '1px solid rgba(191, 160, 70, 0.3)'
              }}
            >
              <h3 style={{ color: '#BFA046' }}>Ethical Sourcing</h3>
              <p>Our commitment to ethical sourcing ensures that all ingredients are obtained responsibly, supporting local communities and sustainable practices.</p>
            </motion.div>
            
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              style={{
                padding: '25px',
                background: 'rgba(18, 18, 18, 0.7)',
                borderRadius: '8px',
                border: '1px solid rgba(191, 160, 70, 0.3)'
              }}
            >
              <h3 style={{ color: '#BFA046' }}>Holistic Wellness</h3>
              <p>Our products are formulated with a holistic approach to beauty and wellness, focusing on nourishing your skin while promoting overall well-being.</p>
            </motion.div>
          </div>
        </motion.section>
      </div>
    </motion.div>
  );
};

export default RahimNaturalsPage; 