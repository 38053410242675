import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const BrandCard = ({ brand, index }) => {
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: 'spring',
        stiffness: 100,
        delay: index * 0.1
      }
    },
    hover: { 
      scale: 1.05,
      boxShadow: "0px 10px 30px rgba(191, 160, 70, 0.5)",
      transition: { 
        type: 'spring', 
        stiffness: 300,
        damping: 15,
        mass: 1.2,
      }
    }
  };

  // Gold shine animation
  const shineVariants = {
    initial: { left: '-100%' },
    hover: { 
      left: '100%',
      transition: { 
        duration: 0.8,
        ease: 'easeInOut',
        repeat: 1,
        repeatDelay: 0.2
      }
    }
  };

  // Logo container hover effect
  const logoContainerVariants = {
    initial: {
      boxShadow: 'inset 0 0 0px rgba(255, 255, 255, 0)'
    },
    hover: {
      boxShadow: 'inset 0 0 30px rgba(255, 255, 255, 0.2)',
      transition: { duration: 0.4, ease: 'easeOut' }
    }
  };

  // Logo hover animation
  const logoHoverVariants = {
    initial: { 
      scale: 1,
      filter: 'drop-shadow(0 0 0px rgba(255, 255, 255, 0))'
    },
    hover: { 
      scale: 1.1,
      filter: [
        'drop-shadow(0 0 8px rgba(255, 255, 255, 0.6))',
        'drop-shadow(0 0 15px rgba(255, 255, 255, 0.8))',
        'drop-shadow(0 0 8px rgba(255, 255, 255, 0.6))'
      ],
      transition: { 
        filter: {
          repeat: Infinity,
          duration: 1.2,
          ease: 'easeInOut'
        },
        scale: {
          type: 'spring',
          stiffness: 400,
          damping: 10
        }
      }
    }
  };

  // Use consistent color formats for animation
  const goldColor = '#BFA046';

  // Coming Soon question mark animation
  const questionMarkVariants = {
    initial: { 
      opacity: 0,
      scale: 0.5,
      filter: 'drop-shadow(0 0 0px rgba(255, 255, 255, 0))'
    },
    animate: { 
      opacity: 1,
      scale: 1,
      filter: [
        'drop-shadow(0 0 3px rgba(255, 255, 255, 0.3))',
        'drop-shadow(0 0 6px rgba(255, 255, 255, 0.5))',
        'drop-shadow(0 0 3px rgba(255, 255, 255, 0.3))'
      ],
      transition: { 
        filter: {
          repeat: Infinity,
          duration: 2.5
        },
        opacity: { duration: 0.5, delay: index * 0.1 + 0.3 },
        scale: { duration: 0.5, delay: index * 0.1 + 0.3 }
      }
    }
  };

  // Brand name animation
  const brandNameVariants = {
    initial: { 
      backgroundPosition: '100% 0',
      backgroundSize: '200% 100%'
    },
    hover: { 
      backgroundPosition: '0% 0',
      transition: { duration: 0.5 }
    }
  };

  // Service tags animation
  const serviceTagsContainerVariants = {
    initial: {},
    hover: {
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const serviceTagVariants = {
    initial: {},
    hover: {
      y: -5,
      backgroundColor: 'rgba(191, 160, 70, 0.2)',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10
      }
    }
  };

  // Learn More button animation
  const learnMoreVariants = {
    initial: {},
    hover: {
      color: '#FFFFFF',
      transition: { duration: 0.3 }
    }
  };

  const arrowVariants = {
    initial: { x: 0 },
    hover: { 
      x: 7,
      transition: { 
        type: 'spring', 
        stiffness: 400,
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 0.3
      }
    }
  };

  return (
    <motion.div
      className="brand-card"
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      style={{
        borderTop: `4px solid ${goldColor}`,
        position: 'relative', // For absolutely positioned badge
        background: brand.name === "Coming Soon" ? 
          "linear-gradient(135deg, rgba(18,18,18,0.8) 0%, rgba(35,35,35,0.6) 100%)" : 
          undefined,
        height: '100%', // Ensure all cards have the same height
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {brand.isUpcoming && brand.name !== "Coming Soon" && (
        <motion.div 
          className="upcoming-badge"
          initial={{ opacity: 0, scale: 0.8, rotate: -5 }}
          animate={{ 
            opacity: 1, 
            scale: 1, 
            rotate: 0,
            transition: { 
              type: 'spring', 
              stiffness: 200, 
              delay: 0.2 + index * 0.1 
            }
          }}
          whileHover={{
            scale: 1.05,
            y: -2,
            transition: { type: 'spring', stiffness: 300 }
          }}
          style={{
            position: 'absolute',
            top: -15,
            right: -15,
            backgroundColor: goldColor,
            color: '#121212',
            padding: '5px 15px',
            borderRadius: '20px',
            fontWeight: 'bold',
            fontSize: '0.85rem',
            zIndex: 10,
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span>COMING SOON</span>
          <span style={{ fontSize: '0.7rem', opacity: 0.9 }}>{brand.launchDate}</span>
        </motion.div>
      )}

      <motion.div 
        className="brand-card-logo" 
        variants={logoContainerVariants}
        initial="initial"
        whileHover="hover"
        style={{ 
          backgroundColor: brand.name === "Coming Soon" ? 'rgba(191, 160, 70, 0.1)' : '#D4AF3729',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '15px',
          height: '160px', // Increased height even more
          marginBottom: '5px', // Add some spacing
          borderRadius: '4px', // Add slight rounding to the corners
          overflow: 'hidden' // Ensure logo doesn't overflow container
        }}
      >
        {brand.name === "Coming Soon" ? (
          <motion.div
            variants={questionMarkVariants}
            initial="initial"
            animate="animate"
            style={{
              width: '120px',   // Increased size more
              height: '120px',  // Increased size more
              borderRadius: '50%',
              background: 'radial-gradient(circle, rgba(191,160,70,0.3) 0%, rgba(191,160,70,0.1) 70%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '3rem', // Larger question mark
              color: goldColor
            }}
          >
            ?
          </motion.div>
        ) : (
          <motion.img 
            src={brand.logo} 
            alt={`${brand.name} logo`} 
            variants={logoHoverVariants}
            initial="initial"
            whileHover="hover"
            style={{
              maxWidth: '95%',     // Increased from 85% to 95%
              maxHeight: '140px',  // Increased height more
              width: 'auto',
              objectFit: 'contain',
              ...(brand.isUpcoming && brand.name !== "Coming Soon" ? { opacity: 0.7, filter: 'grayscale(40%)' } : {})
            }}
          />
        )}
      </motion.div>
      
      <div className="brand-card-content" style={{ flex: 1 }}>
        <motion.h3 
          className="brand-name"
          variants={brandNameVariants}
          style={{ 
            color: goldColor,
            textAlign: brand.name === "Coming Soon" ? 'center' : 'left',
            marginTop: '5px', // Add some spacing
            background: 'linear-gradient(90deg, #BFA046, #E2D1A7)',
            backgroundSize: '200% 100%',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            backgroundPosition: '100% 0'
          }}
        >
          {brand.name}
        </motion.h3>
        <p className="brand-description">{brand.shortDescription}</p>
        
        <motion.div 
          className="brand-services"
          variants={serviceTagsContainerVariants}
        >
          {brand.services.slice(0, 2).map((service, idx) => (
            <motion.span 
              key={idx} 
              className="service-tag" 
              variants={serviceTagVariants}
              style={{ backgroundColor: 'rgba(191, 160, 70, 0.1)', color: goldColor }}
            >
              {service}
            </motion.span>
          ))}
          {brand.services.length > 2 && (
            <motion.span 
              className="service-tag more-tag"
              variants={serviceTagVariants}
            >
              +{brand.services.length - 2} more
            </motion.span>
          )}
        </motion.div>
      </div>
      
      <motion.div 
        className="brand-card-footer"
        whileHover="hover"
        variants={{
          hover: {
            scale: 1.01,
            backgroundColor: 'rgba(191, 160, 70, 0.05)',
            transition: { duration: 0.3 }
          }
        }}
      >
        {brand.isUpcoming ? (
          <span className="btn-link" style={{ color: '#FFFFFF' }}>
            Stay Tuned
            <motion.span 
              animate={{ 
                opacity: [1, 0.5, 1],
                scale: [1, 1.1, 1],
                transition: { 
                  repeat: Infinity, 
                  duration: 1.5 
                }
              }}
            >
              ✨
            </motion.span>
          </span>
        ) : (
          <motion.div
            variants={learnMoreVariants}
            style={{ display: 'inline-block' }}
          >
            <Link 
              to={
                brand.slug === 'warhorse-gear' 
                  ? '/warhorse-gear' 
                  : brand.slug === 'rahim-naturals'
                    ? '/rahim-naturals'
                    : `/brands/${brand.slug}`
              } 
              className="btn-link"
              style={{ color: 'inherit' }}
            >
              Learn More
              <motion.span 
                variants={arrowVariants}
                style={{ display: 'inline-block' }}
              >
                →
              </motion.span>
            </Link>
          </motion.div>
        )}

        {/* Gold to white shine effect */}
        <motion.div
          style={{
            position: 'absolute',
            top: 0,
            left: '-100%',
            width: '50%',
            height: '100%',
            background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0) 100%)',
            transform: 'skewX(-20deg)',
            zIndex: 1,
            pointerEvents: 'none'
          }}
          variants={shineVariants}
          initial="initial"
          whileHover="hover"
        />
      </motion.div>
    </motion.div>
  );
};

export default BrandCard; 