import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import PageTitle from '../components/common/PageTitle';
import brands from '../data/brands';
import '../styles/WarhorseGearPage.css';

const WarhorseGearPage = () => {
  // Animation variants
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const childVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  const shineAnimation = {
    initial: {
      backgroundPosition: '200% center',
    },
    animate: {
      backgroundPosition: '-200% center',
      transition: {
        repeat: Infinity,
        duration: 10,
        ease: 'linear',
      },
    },
  };

  // Get the Warhorse Gear brand data
  const warhorseGear = brands.find(b => b.slug === 'warhorse-gear');

  return (
    <motion.div 
      className="brand-page-container"
      variants={pageVariants}
      initial="initial"
      animate="animate"
    >
      <PageTitle 
        title="Warhorse Gear" 
        subtitle="Tech-driven fitness and sports equipment" 
      />
      
      <div className="brand-page-content">
        <motion.section className="brand-hero-section" variants={childVariants}>
          <motion.div 
            className="brand-image-container"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.7 }}
          >
            <img 
              src={warhorseGear.logo} 
              alt="Warhorse Gear logo" 
              className="brand-logo"
              style={{
                maxWidth: '250px',
                filter: 'drop-shadow(0 0 15px rgba(191, 160, 70, 0.4))'
              }}
            />
          </motion.div>
          
          <motion.div className="brand-intro" variants={childVariants}>
            <h2>Pioneering the Future of Fitness</h2>
            <p>{warhorseGear.description}</p>
          </motion.div>
        </motion.section>

        <motion.section className="app-showcase-section" variants={childVariants}>
          <motion.h2
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              textAlign: 'center',
              marginBottom: '40px'
            }}
            variants={shineAnimation}
            initial="initial"
            animate="animate"
          >
            Warhorse Gear Soccer Trainer App
          </motion.h2>

          <div className="app-showcase-container">
            <motion.div 
              className="app-icon-container"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }}
              whileHover={{ 
                scale: 1.05,
                filter: 'drop-shadow(0 0 15px rgba(191, 160, 70, 0.7))'
              }}
            >
              {/* App icon */}
              <img 
                src="/images/wg_app_icon.png" 
                alt="Warhorse Gear Soccer Trainer App" 
                className="app-icon"
                style={{
                  width: '140px',
                  height: '140px',
                  borderRadius: '24px',
                  boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)',
                  objectFit: 'cover'
                }}
              />

              <div 
                className="app-platforms"
                style={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'center',
                  marginTop: '15px'
                }}
              >
                <span 
                  style={{
                    padding: '5px 12px',
                    background: 'rgba(18, 18, 18, 0.7)',
                    borderRadius: '15px',
                    fontSize: '0.8rem',
                    color: '#BFA046',
                    border: '1px solid rgba(191, 160, 70, 0.3)'
                  }}
                >
                  iOS
                </span>
                <span 
                  style={{
                    padding: '5px 12px',
                    background: 'rgba(18, 18, 18, 0.7)',
                    borderRadius: '15px',
                    fontSize: '0.8rem',
                    color: '#BFA046',
                    border: '1px solid rgba(191, 160, 70, 0.3)'
                  }}
                >
                  Android
                </span>
              </div>
              
              <Link to="/warhorse-gear/app-privacy-policy" style={{ textDecoration: 'none' }}>
                <motion.button
                  className="app-privacy-button"
                  whileHover={{ scale: 1.05, boxShadow: "0px 4px 10px rgba(191, 160, 70, 0.4)" }}
                  whileTap={{ scale: 0.98 }}
                  style={{
                    marginTop: '20px',
                    padding: '10px 18px',
                    background: 'rgba(191, 160, 70, 0.15)',
                    border: '1px solid rgba(191, 160, 70, 0.7)',
                    borderRadius: '8px',
                    color: '#BFA046',
                    fontSize: '0.9rem',
                    fontWeight: '500',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)'
                  }}
                >
                  <span style={{ fontSize: '1rem' }}>📋</span>
                  View App Privacy Policy
                </motion.button>
              </Link>
            </motion.div>

            <motion.div 
              className="app-description"
              initial={{ opacity: 0, x: 30 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              <h3 style={{ marginBottom: '15px', color: '#BFA046' }}>Elevate Your Soccer Skills</h3>
              <p style={{ marginBottom: '20px' }}>
                The Warhorse Gear Soccer Trainer App is your personal coach for improving dribbling
                and ball mastery skills. Designed to work seamlessly with our Soccer Training Mat,
                the app offers comprehensive video modules and interactive minigames that make
                training engaging and effective.
              </p>
              <div style={{ marginBottom: '20px' }}>
                <h4 style={{ marginBottom: '10px', fontSize: '1.1rem', color: '#BFA046' }}>Key Features:</h4>
                <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
                  <li style={{ marginBottom: '8px' }}>Step-by-step video tutorials for all skill levels</li>
                  <li style={{ marginBottom: '8px' }}>Interactive minigames that track your progress</li>
                  <li style={{ marginBottom: '8px' }}>Personalized training programs based on your skill level</li>
                  <li style={{ marginBottom: '8px' }}>Works with the Warhorse Gear Soccer Training Mat for optimal results</li>
                </ul>
              </div>
            </motion.div>
          </div>
        </motion.section>

        <motion.section className="brand-services-section" variants={childVariants}>
          <motion.h2
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            variants={shineAnimation}
            initial="initial"
            animate="animate"
          >
            Our Products & Services
          </motion.h2>
          <div className="services-grid">
            {warhorseGear.services.map((service, index) => (
              <motion.div 
                key={index} 
                className="service-card"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0px 5px 15px rgba(191, 160, 70, 0.3)",
                  borderColor: "rgba(191, 160, 70, 0.6)",
                  y: -5
                }}
                style={{
                  padding: '25px',
                  background: 'rgba(18, 18, 18, 0.7)',
                  borderRadius: '8px',
                  border: '1px solid rgba(191, 160, 70, 0.3)',
                  position: 'relative',
                  overflow: 'hidden'
                }}
              >
                <h3 style={{ color: '#BFA046', marginBottom: '10px' }}>{service}</h3>
                
                {/* Service card shine effect */}
                <motion.div
                  className="service-shine"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: '-100%',
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(191, 160, 70, 0.1) 50%, rgba(255,255,255,0) 100%)',
                    transform: 'skewX(-20deg)',
                    zIndex: 1,
                    pointerEvents: 'none'
                  }}
                  animate={{
                    left: ['100%', '-100%'],
                    transition: {
                      repeat: Infinity,
                      duration: 8,
                      ease: 'easeInOut',
                      delay: index * 0.5
                    }
                  }}
                />
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section className="brand-features-section" variants={childVariants}>
          <motion.h2
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            variants={shineAnimation}
            initial="initial"
            animate="animate"
          >
            Why Choose Warhorse Gear
          </motion.h2>
          
          <div className="features-grid">
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              style={{
                padding: '25px',
                background: 'rgba(18, 18, 18, 0.7)',
                borderRadius: '8px',
                border: '1px solid rgba(191, 160, 70, 0.3)'
              }}
            >
              <h3 style={{ color: '#BFA046' }}>Innovative Technology</h3>
              <p>Our products integrate cutting-edge technology to enhance your workout experience and track your performance with precision.</p>
            </motion.div>
            
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              style={{
                padding: '25px',
                background: 'rgba(18, 18, 18, 0.7)',
                borderRadius: '8px',
                border: '1px solid rgba(191, 160, 70, 0.3)'
              }}
            >
              <h3 style={{ color: '#BFA046' }}>Superior Quality</h3>
              <p>Every Warhorse Gear product is crafted with premium materials, designed to withstand intense workouts and deliver lasting performance.</p>
            </motion.div>
            
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              style={{
                padding: '25px',
                background: 'rgba(18, 18, 18, 0.7)',
                borderRadius: '8px',
                border: '1px solid rgba(191, 160, 70, 0.3)'
              }}
            >
              <h3 style={{ color: '#BFA046' }}>Performance Focused</h3>
              <p>We design every product with the athlete in mind, focusing on features that help you achieve peak performance and reach your fitness goals.</p>
            </motion.div>
          </div>
        </motion.section>
      </div>
    </motion.div>
  );
};

export default WarhorseGearPage; 