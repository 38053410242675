import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../../assets/images/logo.svg';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const navbarVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 100, damping: 20 }
    }
  };

  const linkVariants = {
    hover: { 
      scale: 1.05, 
      y: -2,
      textShadow: "0px 0px 8px rgba(191, 160, 70, 0.3)",
    },
    tap: { scale: 0.95 },
    initial: { 
      scale: 1,
      color: isScrolled ? "#FFFFFF" : "#FFFFFF",
      textShadow: "0px 0px 0px rgba(191, 160, 70, 0)"
    },
    active: {
      scale: 1.05,
      color: "#C8B273",
      textShadow: "0px 0px 8px rgba(191, 160, 70, 0.5)"
    }
  };

  const logoContainerVariants = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.05,
      transition: { duration: 0.3 }
    },
    tap: { scale: 0.98 },
    animate: {
      filter: [
        'drop-shadow(0 0 3px rgba(191, 160, 70, 0.3))',
        'drop-shadow(0 0 8px rgba(191, 160, 70, 0.7))',
        'drop-shadow(0 0 3px rgba(191, 160, 70, 0.3))'
      ],
      transition: {
        duration: 3,
        repeat: Infinity,
        ease: 'easeInOut'
      }
    }
  };

  const mobileMenuVariants = {
    closed: { 
      opacity: 0,
      x: '100%',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
        when: "afterChildren",
        staggerChildren: 0.1,
        staggerDirection: -1
      }
    },
    open: { 
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 30,
        when: "beforeChildren",
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const menuItemVariants = {
    closed: { 
      y: 20, 
      opacity: 0,
    },
    open: { 
      y: 0, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 300 }
    }
  };

  return (
    <motion.nav 
      className={`navbar ${isScrolled ? 'scrolled' : ''}`}
      variants={navbarVariants}
      initial="hidden"
      animate="visible"
      style={{
        backgroundColor: isScrolled ? 'rgba(18, 18, 18, 0.95)' : 'transparent',
        boxShadow: isScrolled ? '0 4px 12px rgba(212, 175, 55, 0.15)' : 'none',
        borderBottom: isScrolled ? '1px solid rgba(212, 175, 55, 0.2)' : 'none'
      }}
    >
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <motion.div 
            className="logo-container"
            variants={logoContainerVariants}
            initial="initial"
            animate="animate"
            whileHover="hover"
            whileTap="tap"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px'
            }}
          >
            <motion.img 
              src={logo} 
              alt="Guntaka Ventures Logo" 
              style={{ 
                height: '35px',
                filter: 'drop-shadow(0 0 5px rgba(191, 160, 70, 0.5))'
              }}
            />
            <motion.span 
              className="logo-text"
              style={{
                background: 'linear-gradient(90deg, #BFA046, #E2D1A7, #9C7A3C, #BFA046)',
                backgroundSize: '300% 100%',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                backgroundPosition: isScrolled ? '0% center' : '100% center',
                transition: 'background-position 5s ease-in-out',
                fontWeight: 'bold',
                fontSize: '1.3rem',
                letterSpacing: '0.5px'
              }}
              animate={{
                backgroundPosition: ['0% center', '100% center', '0% center'],
                transition: {
                  duration: 8,
                  repeat: Infinity,
                  ease: 'linear'
                }
              }}
            >
              Guntaka Ventures
            </motion.span>
          </motion.div>
        </Link>

        <div className="nav-links desktop-nav">
          <motion.div
            variants={linkVariants}
            initial="initial"
            whileHover={location.pathname === '/' ? undefined : "hover"}
            whileTap="tap"
            animate={location.pathname === '/' ? "active" : "initial"}
            className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}
          >
            <Link to="/">
              Home
              {location.pathname === '/' && (
                <motion.div 
                  className="active-indicator"
                  layoutId="activeNavIndicator"
                  initial={{ width: 0 }}
                  animate={{ 
                    width: '100%',
                    transition: { duration: 0.3 }
                  }}
                  style={{ 
                    height: '2px', 
                    background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C)',
                    position: 'absolute',
                    bottom: '-3px',
                    left: 0,
                    borderRadius: '1px'
                  }}
                />
              )}
            </Link>
          </motion.div>
          <motion.div
            variants={linkVariants}
            initial="initial"
            whileHover={location.pathname === '/about' ? undefined : "hover"}
            whileTap="tap"
            animate={location.pathname === '/about' ? "active" : "initial"}
            className={location.pathname === '/about' ? 'nav-link active' : 'nav-link'}
          >
            <Link to="/about">
              About
              {location.pathname === '/about' && (
                <motion.div 
                  className="active-indicator"
                  layoutId="activeNavIndicator"
                  style={{ 
                    height: '2px', 
                    background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C)',
                    position: 'absolute',
                    bottom: '-3px',
                    left: 0,
                    width: '100%',
                    borderRadius: '1px'
                  }}
                />
              )}
            </Link>
          </motion.div>
          <motion.div
            variants={linkVariants}
            initial="initial"
            whileHover={location.pathname.includes('/brands') ? undefined : "hover"}
            whileTap="tap"
            animate={location.pathname.includes('/brands') ? "active" : "initial"}
            className={location.pathname.includes('/brands') ? 'nav-link active' : 'nav-link'}
          >
            <Link to="/brands">
              Our Brands
              {location.pathname.includes('/brands') && (
                <motion.div 
                  className="active-indicator"
                  layoutId="activeNavIndicator"
                  style={{ 
                    height: '2px', 
                    background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C)',
                    position: 'absolute',
                    bottom: '-3px',
                    left: 0,
                    width: '100%',
                    borderRadius: '1px'
                  }}
                />
              )}
            </Link>
          </motion.div>
          <motion.div
            variants={linkVariants}
            initial="initial"
            whileHover={location.pathname === '/contact' ? undefined : "hover"}
            whileTap="tap"
            animate={location.pathname === '/contact' ? "active" : "initial"}
            className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'}
          >
            <Link to="/contact">
              Contact
              {location.pathname === '/contact' && (
                <motion.div 
                  className="active-indicator"
                  layoutId="activeNavIndicator"
                  style={{ 
                    height: '2px', 
                    background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C)',
                    position: 'absolute',
                    bottom: '-3px',
                    left: 0,
                    width: '100%',
                    borderRadius: '1px'
                  }}
                />
              )}
            </Link>
          </motion.div>
        </div>

        <motion.div 
          className="mobile-menu-button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <motion.div 
            className={`menu-icon ${isMenuOpen ? 'open' : ''}`}
            style={{
              backgroundColor: isMenuOpen ? 'transparent' : '#BFA046'
            }}
          >
            <motion.span 
              className="menu-icon-top"
              animate={isMenuOpen ? { rotate: 45, y: 8, backgroundColor: '#C8B273' } : { rotate: 0, y: 0, backgroundColor: '#BFA046' }}
              transition={{ duration: 0.2 }}
            />
            <motion.span 
              className="menu-icon-middle"
              animate={isMenuOpen ? { opacity: 0 } : { opacity: 1 }}
              transition={{ duration: 0.2 }}
              style={{ backgroundColor: '#BFA046' }}
            />
            <motion.span 
              className="menu-icon-bottom"
              animate={isMenuOpen ? { rotate: -45, y: -8, backgroundColor: '#C8B273' } : { rotate: 0, y: 0, backgroundColor: '#BFA046' }}
              transition={{ duration: 0.2 }}
            />
          </motion.div>
        </motion.div>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div 
            className="mobile-menu"
            variants={mobileMenuVariants}
            initial="closed"
            animate="open"
            exit="closed"
            style={{
              backgroundColor: 'rgba(18, 18, 18, 0.98)',
              borderLeft: '1px solid rgba(191, 160, 70, 0.3)',
              boxShadow: '-5px 0 20px rgba(0, 0, 0, 0.5)'
            }}
          >
            <motion.div className="mobile-nav-links">
              <motion.div 
                variants={menuItemVariants}
                whileHover={location.pathname === '/' ? undefined : { x: 5 }}
                transition={{ type: 'spring', stiffness: 300 }}
                className={location.pathname === '/' ? 'mobile-nav-link active' : 'mobile-nav-link'}
              >
                <Link to="/">
                  Home
                  {location.pathname === '/' && (
                    <motion.div 
                      className="active-mobile-indicator"
                      layoutId="activeMobileIndicator"
                      style={{ 
                        width: '4px',
                        height: '100%',
                        background: 'linear-gradient(0deg, #BFA046, #C8B273)',
                        position: 'absolute',
                        left: '-10px',
                        top: 0,
                        borderRadius: '2px'
                      }}
                    />
                  )}
                </Link>
              </motion.div>
              <motion.div 
                variants={menuItemVariants}
                whileHover={location.pathname === '/about' ? undefined : { x: 5 }}
                transition={{ type: 'spring', stiffness: 300 }}
                className={location.pathname === '/about' ? 'mobile-nav-link active' : 'mobile-nav-link'}
              >
                <Link to="/about">
                  About
                  {location.pathname === '/about' && (
                    <motion.div 
                      className="active-mobile-indicator"
                      layoutId="activeMobileIndicator"
                      style={{ 
                        width: '4px',
                        height: '100%',
                        background: 'linear-gradient(0deg, #BFA046, #C8B273)',
                        position: 'absolute',
                        left: '-10px',
                        top: 0,
                        borderRadius: '2px'
                      }}
                    />
                  )}
                </Link>
              </motion.div>
              <motion.div 
                variants={menuItemVariants}
                whileHover={location.pathname.includes('/brands') ? undefined : { x: 5 }}
                transition={{ type: 'spring', stiffness: 300 }}
                className={location.pathname.includes('/brands') ? 'mobile-nav-link active' : 'mobile-nav-link'}
              >
                <Link to="/brands">
                  Our Brands
                  {location.pathname.includes('/brands') && (
                    <motion.div 
                      className="active-mobile-indicator"
                      layoutId="activeMobileIndicator"
                      style={{ 
                        width: '4px',
                        height: '100%',
                        background: 'linear-gradient(0deg, #BFA046, #C8B273)',
                        position: 'absolute',
                        left: '-10px',
                        top: 0,
                        borderRadius: '2px'
                      }}
                    />
                  )}
                </Link>
              </motion.div>
              <motion.div 
                variants={menuItemVariants}
                whileHover={location.pathname === '/contact' ? undefined : { x: 5 }}
                transition={{ type: 'spring', stiffness: 300 }}
                className={location.pathname === '/contact' ? 'mobile-nav-link active' : 'mobile-nav-link'}
              >
                <Link to="/contact">
                  Contact
                  {location.pathname === '/contact' && (
                    <motion.div 
                      className="active-mobile-indicator"
                      layoutId="activeMobileIndicator"
                      style={{ 
                        width: '4px',
                        height: '100%',
                        background: 'linear-gradient(0deg, #BFA046, #C8B273)',
                        position: 'absolute',
                        left: '-10px',
                        top: 0,
                        borderRadius: '2px'
                      }}
                    />
                  )}
                </Link>
              </motion.div>
            </motion.div>
            
            {/* Gold accent elements */}
            <motion.div
              className="mobile-menu-accent"
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '5px',
                background: 'linear-gradient(90deg, #121212, #BFA046, #121212)',
              }}
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            />
            
            <motion.div
              className="menu-shine-effect"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '200%',
                height: '100%',
                background: 'linear-gradient(90deg, rgba(191,160,70,0) 0%, rgba(191,160,70,0.05) 50%, rgba(191,160,70,0) 100%)',
                transform: 'skewX(-20deg)',
                pointerEvents: 'none'
              }}
              animate={{
                x: ['-100%', '50%'],
                transition: {
                  repeat: Infinity,
                  duration: 10,
                  ease: 'linear'
                }
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default Navbar; 