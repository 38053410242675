import React, { useState } from 'react';
import { motion } from 'framer-motion';

const ContactForm = ({ brand }) => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState({
    isSubmitting: false,
    isSubmitted: false,
    error: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormStatus({ isSubmitting: true, isSubmitted: false, error: null });

    // Simulate API call delay
    setTimeout(() => {
      // Normally you would post data to an API here
      console.log('Form submitted:', formState);
      
      // Reset form
      setFormState({ name: '', email: '', message: '' });
      setFormStatus({ isSubmitting: false, isSubmitted: true, error: null });
    }, 1500);
  };

  const inputVariants = {
    focus: { 
      scale: 1.02,
      boxShadow: '0 0 10px rgba(191, 160, 70, 0.5)', 
      borderColor: '#BFA046'
    },
    blur: { 
      scale: 1,
      boxShadow: 'none', 
      borderColor: 'rgba(191, 160, 70, 0.3)'
    }
  };

  const buttonVariants = {
    initial: { scale: 1 },
    hover: { 
      scale: 1.05,
      boxShadow: '0 0 15px rgba(191, 160, 70, 0.6)',
      background: 'linear-gradient(45deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
      backgroundSize: '300% 100%',
      transition: { 
        backgroundPosition: {
          repeat: Infinity,
          duration: 3,
          from: '0% center',
          to: '300% center',
        }
      }
    },
    tap: { scale: 0.98 },
    loading: {
      scale: [1, 1.03, 1],
      boxShadow: ['0 0 10px rgba(191, 160, 70, 0.3)', '0 0 15px rgba(191, 160, 70, 0.7)', '0 0 10px rgba(191, 160, 70, 0.3)'],
      transition: {
        duration: 1.5,
        repeat: Infinity
      }
    }
  };

  return (
    <motion.div 
      className="contact-form"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
    >
      {formStatus.isSubmitted ? (
        <motion.div 
          className="form-success"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ type: 'spring', stiffness: 200, damping: 20 }}
        >
          <motion.div 
            className="success-icon"
            initial={{ scale: 0 }}
            animate={{
              scale: 1,
              transition: { delay: 0.3, type: 'spring', stiffness: 300 }
            }}
          >
            <svg viewBox="0 0 24 24" width="60" height="60">
              <motion.path
                d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
                fill="#BFA046"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ delay: 0.4, duration: 0.8, ease: "easeOut" }}
              />
            </svg>
          </motion.div>
          <h3>Thank you!</h3>
          <p>Your message has been sent to {brand.name}. We'll be in touch soon.</p>
          <motion.button 
            className="new-message-btn"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setFormStatus({ isSubmitting: false, isSubmitted: false, error: null })}
            style={{
              background: 'linear-gradient(45deg, #BFA046, #C8B273)',
              color: '#121212',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '4px',
              cursor: 'pointer',
              marginTop: '20px',
              fontWeight: 'bold'
            }}
          >
            Send Another Message
          </motion.button>
        </motion.div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-header">
            <h3>Send a message to {brand.name}</h3>
            <p>We'll get back to you as soon as possible</p>
          </div>
          
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <motion.input
              type="text"
              id="name"
              name="name"
              value={formState.name}
              onChange={handleChange}
              required
              variants={inputVariants}
              initial="blur"
              whileFocus="focus"
              animate="blur"
              style={{
                backgroundColor: 'rgba(18, 18, 18, 0.7)',
                color: '#fff',
                border: '1px solid rgba(191, 160, 70, 0.3)',
                borderRadius: '4px'
              }}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Your Email</label>
            <motion.input
              type="email"
              id="email"
              name="email"
              value={formState.email}
              onChange={handleChange}
              required
              variants={inputVariants}
              initial="blur"
              whileFocus="focus"
              animate="blur"
              style={{
                backgroundColor: 'rgba(18, 18, 18, 0.7)',
                color: '#fff',
                border: '1px solid rgba(191, 160, 70, 0.3)',
                borderRadius: '4px'
              }}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="message">Your Message</label>
            <motion.textarea
              id="message"
              name="message"
              value={formState.message}
              onChange={handleChange}
              required
              rows={5}
              variants={inputVariants}
              initial="blur"
              whileFocus="focus"
              animate="blur"
              style={{
                backgroundColor: 'rgba(18, 18, 18, 0.7)',
                color: '#fff',
                border: '1px solid rgba(191, 160, 70, 0.3)',
                borderRadius: '4px'
              }}
            />
          </div>
          
          <motion.button
            type="submit"
            disabled={formStatus.isSubmitting}
            variants={buttonVariants}
            initial="initial"
            whileHover="hover"
            whileTap="tap"
            animate={formStatus.isSubmitting ? "loading" : "initial"}
            className="submit-button"
            style={{
              background: 'linear-gradient(to right, #BFA046, #9C7A3C)',
              color: '#121212',
              border: 'none',
              fontWeight: 'bold'
            }}
          >
            {formStatus.isSubmitting ? 'Sending...' : 'Send Message'}
            
            {/* Gold shimmer effect */}
            <motion.div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0) 100%)',
                transform: 'skewX(-20deg)',
                zIndex: 1,
                pointerEvents: 'none'
              }}
              animate={{
                x: ['-100%', '300%'],
                transition: {
                  repeat: Infinity,
                  duration: 2,
                  ease: 'easeInOut',
                  repeatDelay: 1
                }
              }}
            />
          </motion.button>
        </form>
      )}
      
      {/* Gold particle background effect */}
      <motion.div
        className="form-background-effect"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'radial-gradient(circle at 50% 50%, rgba(191, 160, 70, 0.05) 0%, rgba(30, 30, 30, 0) 70%)',
          zIndex: -1,
          pointerEvents: 'none'
        }}
        animate={{
          opacity: [0.4, 0.7, 0.4],
          scale: [1, 1.05, 1],
          transition: {
            duration: 10,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut'
          }
        }}
      />
    </motion.div>
  );
};

export default ContactForm; 