import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/common/Layout';
import ScrollToTop from './components/common/ScrollToTop';
import HomePage from './pages/HomePage';
import BrandsPage from './pages/BrandsPage';
import BrandDetail from './components/brands/BrandDetail';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import WarhorseGearPage from './pages/WarhorseGearPage';
import RahimNaturalsPage from './pages/RahimNaturalsPage';
import AppPrivacyPolicyPage from './pages/AppPrivacyPolicyPage';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/brands" element={<BrandsPage />} />
          <Route path="/brands/:slug" element={<BrandDetail />} />
          <Route path="/warhorse-gear" element={<WarhorseGearPage />} />
          <Route path="/rahim-naturals" element={<RahimNaturalsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/warhorse-gear/app-privacy-policy" element={<AppPrivacyPolicyPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
