import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import PageTitle from '../components/common/PageTitle';

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <PageTitle title="404" subtitle="Page Not Found" />
      
      <motion.div 
        className="not-found-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          textAlign: 'center',
          padding: '0 20px 60px',
          maxWidth: '600px',
          margin: '0 auto'
        }}
      >
        <motion.div
          className="not-found-icon"
          initial={{ scale: 0 }}
          animate={{ scale: 1, rotateY: [0, 360] }}
          transition={{ 
            scale: { duration: 0.5 },
            rotateY: { duration: 1.5, ease: 'easeInOut' }
          }}
          style={{
            fontSize: '8rem',
            marginBottom: '20px',
            display: 'inline-block',
            background: 'linear-gradient(135deg, #BFA046, #C8B273)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text'
          }}
        >
          ?
        </motion.div>
        
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          style={{
            fontSize: '1.2rem',
            marginBottom: '30px',
            color: '#CCCCCC'
          }}
        >
          The page you're looking for doesn't exist or has been moved.
        </motion.p>
        
        <Link to="/">
          <motion.button 
            className="btn primary-btn"
            whileHover={{ 
              scale: 1.05,
              boxShadow: '0 0 25px rgba(191, 160, 70, 0.6)'
            }}
            whileTap={{ scale: 0.95 }}
            style={{
              background: 'linear-gradient(to right, #BFA046, #9C7A3C)',
              color: '#121212',
              border: 'none',
              padding: '12px 24px',
              borderRadius: '4px',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
          >
            Return to Homepage
          </motion.button>
        </Link>
        
        {/* Background decorative elements */}
        <motion.div
          className="decorative-element left"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 0.1, scale: 1, rotate: [0, 360] }}
          transition={{ 
            opacity: { delay: 0.8, duration: 1 },
            scale: { delay: 0.8, duration: 1 },
            rotate: { delay: 1, duration: 20, repeat: Infinity, ease: 'linear' }
          }}
          style={{
            position: 'absolute',
            top: '30%',
            left: '10%',
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            background: 'transparent',
            border: '3px solid #BFA046',
            zIndex: -1
          }}
        />
        
        <motion.div
          className="decorative-element right"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 0.1, scale: 1, rotate: [0, -360] }}
          transition={{ 
            opacity: { delay: 1, duration: 1 },
            scale: { delay: 1, duration: 1 },
            rotate: { delay: 1, duration: 25, repeat: Infinity, ease: 'linear' }
          }}
          style={{
            position: 'absolute',
            bottom: '20%',
            right: '10%',
            width: '200px',
            height: '200px',
            borderRadius: '30%',
            background: 'transparent',
            border: '2px solid #C8B273',
            zIndex: -1
          }}
        />
      </motion.div>
    </div>
  );
};

export default NotFoundPage; 