import React from 'react';
import { motion } from 'framer-motion';
import { FaEnvelope, FaQuestion, FaTools, FaHandshake } from 'react-icons/fa';
import PageTitle from '../components/common/PageTitle';

const ContactPage = () => {
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        staggerChildren: 0.1 
      }
    }
  };
  
  const itemVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const inquiryTypes = [
    {
      icon: <FaQuestion />,
      title: "General Inquiries",
      description: "Questions about our company, services, or offerings"
    },
    {
      icon: <FaTools />,
      title: "Product Support",
      description: "Technical assistance or help with our products"
    },
    {
      icon: <FaHandshake />,
      title: "Business Partnerships",
      description: "Collaboration opportunities or strategic partnerships"
    }
  ];

  return (
    <div className="contact-page">
      <PageTitle 
        title="Contact Us" 
        subtitle="We're here to help with your questions and inquiries" 
      />

      <motion.div 
        className="container"
        variants={pageVariants}
        initial="initial"
        animate="animate"
      >
        <motion.div className="contact-main" variants={itemVariants}>
          <div className="contact-info-section">
            <h2>Get in Touch</h2>
            <p>
              Thank you for your interest in Guntaka Ventures. We're here to help with any questions
              or needs you might have. The best way to reach us is through email.
            </p>
            
            <div className="contact-email-highlight">
              <FaEnvelope className="contact-icon" />
              <a href="mailto:info@guntakaventures.com" className="gold-email">info@guntakaventures.com</a>
            </div>
          </div>
        </motion.div>

        <motion.div className="inquiry-types-section" variants={itemVariants}>
          <h2>How Can We Help?</h2>
          <p>Please include the nature of your inquiry in your email for faster response times.</p>
          
          <div className="inquiry-types-grid">
            {inquiryTypes.map((type, index) => (
              <motion.div 
                className="inquiry-card"
                key={index}
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.03,
                  boxShadow: "0px 10px 30px rgba(191, 160, 70, 0.2)"
                }}
              >
                <div className="inquiry-icon">{type.icon}</div>
                <h3>{type.title}</h3>
                <p>{type.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div className="contact-note" variants={itemVariants}>
          <p>Our team strives to respond to all inquiries within 24-48 business hours. For urgent matters, 
          please include "URGENT" in your email subject line.</p>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ContactPage; 