import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useSpring, animated } from '@react-spring/web';
import brands from '../../data/brands';
import ContactForm from './ContactForm';

const BrandDetail = () => {
  const { slug } = useParams();
  const brand = brands.find(b => b.slug === slug);

  // Animation variants
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const childVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  // React Spring animations - moved before conditional return
  const heroProps = useSpring({
    from: { transform: 'scale(1.1)', opacity: 0 },
    to: { transform: 'scale(1)', opacity: 1 },
    config: { tension: 120, friction: 14 },
    delay: 300
  });

  // Shine animation for title
  const shineAnimation = {
    initial: {
      backgroundPosition: '200% center',
    },
    animate: {
      backgroundPosition: '-200% center',
      transition: {
        repeat: Infinity,
        duration: 10,
        ease: 'linear',
      },
    },
  };

  if (!brand) {
    return (
      <div className="brand-not-found">
        <h2>Brand not found</h2>
        <Link to="/">Return to home</Link>
      </div>
    );
  }

  return (
    <motion.div 
      className="brand-detail-container"
      variants={pageVariants}
      initial="initial"
      animate="animate"
    >
      {/* Breadcrumb navigation */}
      <motion.div 
        className="breadcrumb-navigation"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        style={{
          padding: '15px 20px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.9rem',
          color: '#C8B273'
        }}
      >
        <Link to="/" style={{ color: '#C8B273', textDecoration: 'none' }}>Home</Link>
        <span style={{ margin: '0 10px', color: 'rgba(191, 160, 70, 0.6)' }}>/</span>
        <Link to="/brands" style={{ color: '#C8B273', textDecoration: 'none' }}>Our Brands</Link>
        <span style={{ margin: '0 10px', color: 'rgba(191, 160, 70, 0.6)' }}>/</span>
        <span style={{ color: '#FFFFFF' }}>{brand.name}</span>
      </motion.div>

      <animated.div 
        className="brand-hero"
        style={{
          ...heroProps,
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${brand.heroImage})`,
          boxShadow: '0 0 20px rgba(191, 160, 70, 0.3) inset'
        }}
      >
        <div className="brand-hero-content">
          <motion.img 
            src={brand.logo} 
            alt={`${brand.name} logo`} 
            className="brand-logo"
            variants={childVariants}
            whileHover={{
              scale: 1.05,
              filter: 'drop-shadow(0 0 15px rgba(191, 160, 70, 0.8))'
            }}
          />
          <motion.h1 
            variants={childVariants}
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            initial="initial"
            animate="animate"
          >
            {brand.name}
          </motion.h1>
          <motion.p 
            variants={childVariants}
            className="brand-tagline"
          >
            {brand.shortDescription}
          </motion.p>
        </div>
        
        {/* Gold particles effect */}
        <motion.div
          className="gold-particles"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle at 50% 50%, rgba(191, 160, 70, 0.2) 0%, rgba(18, 18, 18, 0) 70%)',
            pointerEvents: 'none'
          }}
          animate={{
            opacity: [0.5, 0.8, 0.5],
            transition: {
              duration: 8,
              repeat: Infinity,
              repeatType: 'reverse',
              ease: 'easeInOut'
            }
          }}
        />
      </animated.div>

      <div className="brand-content">
        <motion.section className="brand-description-section" variants={childVariants}>
          <motion.h2
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            variants={shineAnimation}
            initial="initial"
            animate="animate"
          >
            About {brand.name}
          </motion.h2>
          <p>{brand.description}</p>
        </motion.section>

        <motion.section className="brand-services-section" variants={childVariants}>
          <motion.h2
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            variants={shineAnimation}
            initial="initial"
            animate="animate"
          >
            Our Services
          </motion.h2>
          <div className="services-grid">
            {brand.services.map((service, index) => (
              <motion.div 
                key={index} 
                className="service-card"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0px 5px 15px rgba(191, 160, 70, 0.3)",
                  borderColor: "rgba(191, 160, 70, 0.6)",
                  y: -5
                }}
              >
                <h3>{service}</h3>
                
                {/* Service card shine effect */}
                <motion.div
                  className="service-shine"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: '-100%',
                    width: '100%',
                    height: '100%',
                    background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(191, 160, 70, 0.1) 50%, rgba(255,255,255,0) 100%)',
                    transform: 'skewX(-20deg)',
                    zIndex: 1,
                    pointerEvents: 'none'
                  }}
                  animate={{
                    left: ['100%', '-100%'],
                    transition: {
                      repeat: Infinity,
                      duration: 8,
                      ease: 'easeInOut',
                      delay: index * 0.5
                    }
                  }}
                />
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section className="brand-contact-section" variants={childVariants}>
          <motion.h2
            style={{
              background: 'linear-gradient(90deg, #BFA046, #C8B273, #9C7A3C, #BFA046)',
              backgroundSize: '400% 100%',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text'
            }}
            variants={shineAnimation}
            initial="initial"
            animate="animate"
          >
            Get in Touch
          </motion.h2>
          <div className="contact-container">
            <div className="contact-info">
              <div className="contact-item">
                <h4>Email</h4>
                <p>{brand.contactEmail}</p>
              </div>
              <div className="contact-item">
                <h4>Phone</h4>
                <p>{brand.contactPhone}</p>
              </div>
              <div className="contact-item">
                <h4>Social Media</h4>
                <div className="social-links">
                  {Object.entries(brand.socialMedia).map(([platform, url]) => (
                    <motion.a 
                      key={platform} 
                      href={url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="social-link"
                      whileHover={{ 
                        scale: 1.05,
                        backgroundColor: 'rgba(191, 160, 70, 0.3)',
                        boxShadow: '0 0 10px rgba(191, 160, 70, 0.3)'
                      }}
                    >
                      {platform}
                    </motion.a>
                  ))}
                </div>
              </div>
            </div>
            
            <motion.div 
              className="contact-form-container"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5 }}
            >
              <ContactForm brand={brand} />
            </motion.div>
          </div>
        </motion.section>
      </div>
    </motion.div>
  );
};

export default BrandDetail; 