import React from 'react';
import { motion } from 'framer-motion';
import PageTitle from '../components/common/PageTitle';
import { Link } from 'react-router-dom';
import '../styles/PrivacyPolicyPage.css';

const AppPrivacyPolicyPage = () => {
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { 
        duration: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const childVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
      opacity: 1, 
      y: 0,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  return (
    <motion.div 
      className="page-container privacy-policy-container"
      variants={pageVariants}
      initial="initial"
      animate="animate"
    >
      <PageTitle 
        title="Soccer Trainer App Privacy Policy" 
        subtitle="Last updated: March 25, 2025" 
      />
      
      <motion.div 
        className="breadcrumb-navigation"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        style={{
          padding: '15px 20px',
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.9rem',
          color: '#C8B273',
          marginBottom: '20px'
        }}
      >
        <Link to="/" style={{ color: '#C8B273', textDecoration: 'none' }}>Home</Link>
        <span style={{ margin: '0 10px', color: 'rgba(191, 160, 70, 0.6)' }}>/</span>
        <Link to="/warhorse-gear" style={{ color: '#C8B273', textDecoration: 'none' }}>Warhorse Gear</Link>
        <span style={{ margin: '0 10px', color: 'rgba(191, 160, 70, 0.6)' }}>/</span>
        <span style={{ color: '#FFFFFF' }}>App Privacy Policy</span>
      </motion.div>
      
      <motion.div 
        className="privacy-policy-content"
        variants={childVariants}
      >
        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Introduction</h2>
          <p>
            Warhorse Gear ("we", "our", or "us") is committed to protecting the privacy of users ("you" or "your") 
            of our Soccer Trainer mobile application (the "App"). This Privacy Policy explains what information we collect, 
            how we use it, and your rights in relation to that information.
          </p>
          <p>
            By downloading, installing, or using the App, you agree to the collection and use of your information 
            in accordance with this Privacy Policy.
          </p>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Information We Collect</h2>
          <p>We collect the following types of information:</p>
          
          <h3>Account Information</h3>
          <p>When you create an account, we collect:</p>
          <ul>
            <li>Username</li>
            <li>Email address</li>
          </ul>

          <h3>Usage Data</h3>
          <p>As you use the App, we collect information about your interaction with the App, including:</p>
          <ul>
            <li>Completion status for video modules</li>
            <li>Best completion times for soccer moves and drills</li>
            <li>Timestamps of your app usage</li>
          </ul>

          <h3>Technical Information</h3>
          <p>We automatically collect certain technical information, such as:</p>
          <ul>
            <li>Device type and model</li>
            <li>Operating system version</li>
            <li>App version</li>
            <li>IP address</li>
          </ul>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To provide and maintain the App's functionality</li>
            <li>To create and manage your account</li>
            <li>To track your progress in the training modules</li>
            <li>To improve and personalize your experience</li>
            <li>To identify and fix technical issues</li>
            <li>To communicate with you about updates or changes to the App</li>
            <li>If you've opted in, to send you marketing communications about our products and services</li>
          </ul>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Marketing Communications</h2>
          <p>
            When you create an account, you have the option to opt out of receiving marketing communications from us. 
            If you choose to receive marketing communications, we may send you information about new features, 
            products, and promotions.
          </p>
          <p>
            You can opt out of marketing communications at any time by:
          </p>
          <ul>
            <li>Updating your preferences in the App settings</li>
            <li>Clicking the "unsubscribe" link in our emails</li>
            <li>Contacting us directly at info@guntakaventures.com</li>
          </ul>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Data Sharing and Disclosure</h2>
          <p>We do not sell your personal information to third parties. We may share your information with:</p>
          <ul>
            <li><strong>Service Providers:</strong> Third-party vendors who help us operate the App (e.g., cloud storage providers, analytics services)</li>
            <li><strong>Affiliated Companies:</strong> Other companies within the Warhorse Gear group</li>
            <li><strong>Legal Requirements:</strong> When required by law, legal process, or to protect our rights and property</li>
          </ul>
          <p>
            All service providers we use are required to maintain the confidentiality and security of your personal information.
          </p>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, 
            disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is 
            100% secure, so we cannot guarantee absolute security.
          </p>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Your Rights</h2>
          <p>Depending on your location, you may have the following rights:</p>
          <ul>
            <li>Access the personal information we hold about you</li>
            <li>Correct inaccurate or incomplete information</li>
            <li>Delete your personal information</li>
            <li>Restrict or object to processing of your personal information</li>
            <li>Request a copy of your personal information in a structured, commonly used, machine-readable format</li>
          </ul>
          <p>
            To exercise these rights, please contact us at info@guntakaventures.com.
          </p>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Children's Privacy</h2>
          <p>
            Our app is rated 4+ on the Apple App Store and E for Everyone on the Google Play Store, indicating it is appropriate for all ages. However, the app is primarily designed for users who are interested in soccer training.
          </p>
          <p>
            While we do not prohibit children under the age of 13 from using the app, the Children's Online Privacy Protection Act (COPPA) requires that we obtain parental consent before collecting personal information from children under 13. If we learn that we have collected personal information from a child under 13 without verification of parental consent, we will take steps to remove that information from our servers.
          </p>
          <p>
            We encourage parents and guardians to monitor their children's internet usage and to help enforce this policy by instructing their children never to provide personal information through the app without their permission. If you believe that we might have any information from or about a child under 13, please contact us at info@guntakaventures.com.
          </p>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy 
            on this page and updating the "Last updated" date at the top of this page. We will also notify you through the App or 
            via email if there are material changes.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective 
            when they are posted on this page.
          </p>
        </motion.div>

        <motion.div className="privacy-section" variants={childVariants}>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy or our data practices, please contact us at:
          </p>
          <div className="contact-block">
            <p>Email: info@guntakaventures.com</p>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default AppPrivacyPolicyPage; 